<template>
<div style="height:100%">
    <iframe
      id="popoutFrame"
      :src="iframeUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
</div>
</template>
<script>
import chartListener from '../mixins/chartListener'
export default {
  mixins:[chartListener],
  data(){
    return {
      iframeUrl: ''
    }
  },
  mounted(){
    this.iframeUrl = JSON.parse(localStorage.getItem('iframeUrl'))
  }
}
</script>
